module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-100},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/favicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a96b8bfbd79ce1a32914ba4e20b2ec48"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1240,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-KFRVRH2","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en","de","pl"],"defaultLanguage":"en","siteUrl":"https://defone.com","trailingSlash":"always","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false},"pages":[{"matchPath":"/metaverse","languages":["en"],"excludeLanguages":["de","pl"]},{"matchPath":"/privacy-policy","languages":["en"],"excludeLanguages":["de","pl"]},{"matchPath":"/blog/","languages":["en"],"excludeLanguages":["de","pl"]},{"matchPath":"/:lang?/blog/:uid","getLanguageFromPath":true,"excludeLanguages":["de","pl"],"languages":["en"]},{"matchPath":"/:lang?/candidates/:uid","getLanguageFromPath":true,"excludeLanguages":["de","pl"],"languages":["en"]},{"matchPath":"/:lang?/careers/:uid","getLanguageFromPath":true,"excludeLanguages":["de","pl"],"languages":["en"]},{"matchPath":"/careers","languages":["en"],"excludeLanguages":["de","pl"]},{"matchPath":"/candidates","languages":["en"],"excludeLanguages":["de","pl"]},{"matchPath":"/open-positions","languages":["en"],"excludeLanguages":["de","pl"]}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
